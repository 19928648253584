import React from 'react';
import { Typography } from '@mui/material';
import color from 'themes/colors';
import PropTypes from 'prop-types';
import { formatUnixToDate } from 'utils/DataParsing';

const DateTypography = ({ label, date, style }) => {
  const formattedDate = formatUnixToDate(date);

  return (
    <Typography variant="body1" style={{ color: color.fontColorSecondary, ...style }}>
      <strong>{label}:</strong> {formattedDate}
    </Typography>
  );
};

DateTypography.propTypes = {
  label: PropTypes.string.isRequired,
  date: PropTypes.number.isRequired,
  style: PropTypes.object
};

DateTypography.defaultProps = {
  style: {}
};

export default DateTypography;
