import ASSETS from 'enums/assets';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  title: 'System.lb_navigation',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'System.lb_dashboard',
      type: 'item',
      url: '/dashboard',
      icon: () => <img src={ASSETS.ICONS.INFO.DASHBOARD} alt="icDashboard" />,
      breadcrumbs: false
    },
    {
      id: 'company',
      title: 'System.lb_company',
      type: 'item',
      url: '/company',
      icon: () => <img src={ASSETS.ICONS.INFO.STOREFRONT} alt="Storefront" />,
      breadcrumbs: false
    },
    {
      id: 'menu-items',
      title: 'System.lb_menu_items',
      type: 'item',
      url: '/menu-items',
      icon: () => <img src={ASSETS.ICONS.INFO.RESTAURANT} alt="Restaurant" />,
      breadcrumbs: false
    },
    {
      id: 'generate-qr-code',
      title: 'System.lb_generate_qr_code',
      type: 'item',
      url: '/generate-qr-code',
      icon: () => <img src={ASSETS.ICONS.INFO.DASHBOARD} alt="icDashboard" />,
      breadcrumbs: false
    },
    {
      id: 'campaigns',
      title: 'System.lb_campaigns',
      type: 'item',
      url: '/campaigns',
      icon: () => <img src={ASSETS.ICONS.INFO.CAMPAIGNS} alt="Campaign" />,
      breadcrumbs: false
    }
  ]
};

export default dashboard;
