import { storage } from 'zFirebaseConfigs/firebase';
import { ref, deleteObject } from 'firebase/storage';

/**
 * Delete the image from the storage by image path.
 * @param {*} imagePath - The image path to delete.
 */
export const deleteImagePath = async (imagePath) => {
  try {
    const imageRef = ref(storage, imagePath);
    await deleteObject(imageRef);
  } catch (error) {
    console.error(`Error deleting campaign banners: ${error.message}`);
    throw error;
  }
};
