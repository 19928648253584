/* eslint-disable no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Button, CircularProgress, TextField } from '@mui/material';
import color from 'themes/colors';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

// EA Design System
import SelectInput from 'components/ea-design-system/SelectInput';
import LogoInput from 'components/ea-design-system/LogoInput';
import MainTitle from 'components/ea-design-system/MainTitle';

// Utils
import { getTodayAsInputFormat } from 'utils/DataParsing';
import { PLACEHOLDERS } from 'constants/globalConstants';
import { getImageForPath } from 'utils/imageUtils';
import i18n from 'i18n';

// Validation Schema TODO: Move this to a Date Utils
const today = new Date();
today.setHours(0, 0, 0, 0); // Truncate the time part

const validationSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required(i18n.t('Campaigns.validation_name_required')),
    menuItemID: Yup.string().required(i18n.t('Campaigns.validation_menu_item_required')),
    bannerUrl: Yup.object()
      .test('at-least-one-banner', i18n.t('Campaigns.validation_at_least_one_banner'), (value) => {
        return value && Object.values(value).some((val) => val !== null && val !== undefined); // Ensure at least one non-null value
      })
      .required(i18n.t('Campaigns.validation_at_least_one_banner')),
    startDate: Yup.date()
      .min(today, i18n.t('Campaigns.validation_start_date_in_past'))
      .required(i18n.t('Campaigns.validation_start_date_required')),
    endDate: Yup.date()
      .min(Yup.ref('startDate'), i18n.t('Campaigns.validation_end_date_before_start'))
      .required(i18n.t('Campaigns.validation_end_date_required'))
  });
};

const CampaignsForm = ({ setValue, name, initialValues, action, showSnackbar, menuItems, languages, defaultLanguage }) => {
  const { t } = useTranslation();

  return (
    <Grid
      sx={{
        padding: '24px 24px',
        width: '100%',
        maxWidth: '100%',
        bgcolor: '#fff',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
        borderRadius: '16px',
        textAlign: 'left',
        '& .MuiTypography-root': { p: 1 },
        '& .MuiTextField-root': { mb: 2 },
        '& .MuiButton-root': { m: 'auto', mt: 2, mb: 2 },
        '& .MuiGrid-root': { justifyContent: 'space-evenly' }
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          console.log('Submitting values:', values);
          if (!values.bannerUrl || Object.keys(values.bannerUrl).length === 0 || !Object.values(values.bannerUrl).some((val) => val)) {
            showSnackbar(t('Campaigns.validation_at_least_one_banner'), 'error');
            setSubmitting(false);
            return;
          }

          action(values)
            .then(() => {
              setSubmitting(false);
            })
            .catch(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ values, setFieldValue, errors, touched, isSubmitting }) => (
          <Form>
            <MainTitle>{name}</MainTitle>

            {/* Name Input */}
            <div style={{ marginBottom: '16px' }}>
              <Field
                component={TextField}
                name="name"
                label={t('Campaigns.lb_campaign_name')}
                value={values.name}
                onChange={(e) => setFieldValue('name', e.target.value)}
                fullWidth
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </div>

            {/* Menu Item Selector */}
            <div style={{ marginBottom: '16px' }}>
              <Field
                name="menuItemID"
                component={SelectInput}
                options={menuItems.map((item) => ({
                  value: item.id,
                  label: item.title[defaultLanguage] || PLACEHOLDERS.UNNAMED
                }))}
                label={t('Campaigns.lb_campaign_select_menu_item')}
                value={
                  menuItems
                    .map((item) => ({
                      value: item.id,
                      label: item.title[defaultLanguage] || PLACEHOLDERS.UNNAMED
                    }))
                    .find((option) => option.value === values.menuItemID) || null
                }
                onChange={(e, newValue) => setFieldValue('menuItemID', newValue?.value || null)}
                getOptionLabel={(option) => option?.label || PLACEHOLDERS.EMPTY}
                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                error={touched.menuItemID && Boolean(errors.menuItemID)}
                helperText={touched.menuItemID && errors.menuItemID}
              />
            </div>

            {/* Date Fields */}
            <div style={{ marginBottom: '16px' }}>
              <Field
                name="startDate"
                component={TextField}
                type="date"
                label={t('Campaigns.lb_campaign_start_date')}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  min: getTodayAsInputFormat() // this restrict dates in the past
                }}
                value={values.startDate}
                onChange={(e) => setFieldValue('startDate', e.target.value)}
                error={touched.startDate && Boolean(errors.startDate)}
                helperText={touched.startDate && errors.startDate}
              />
            </div>
            <div style={{ marginBottom: '16px' }}>
              <Field
                name="endDate"
                component={TextField}
                type="date"
                label={t('Campaigns.lb_campaign_end_date')}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  min: values.startDate || getTodayAsInputFormat() // Restrict dates before startDate
                }}
                value={values.endDate}
                onChange={(e) => setFieldValue('endDate', e.target.value)}
                error={touched.endDate && Boolean(errors.endDate)}
                helperText={touched.endDate && errors.endDate}
              />
            </div>

            {/* Banner Section */}
            <div style={{ marginBottom: '16px' }}>
              <h3>{t('Campaigns.lb_campaign_banners')}</h3>
              {touched.bannerUrl && errors.bannerUrl && <div style={{ color: 'red', marginBottom: '8px' }}>{errors.bannerUrl}</div>}
              {Object.keys(values.bannerUrl || {}).map((lang) => (
                <Grid container key={lang} spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <TextField value={lang} disabled label={t('Language')} fullWidth />
                  </Grid>
                  <Grid item xs={12} container justifyContent="center">
                    <Grid item>
                      <LogoInput
                        value={getImageForPath(values.bannerUrl?.[lang] || PLACEHOLDERS.EMPTY, 1125, 2000, 'webp')}
                        onSetField={(file) => {
                          if (file instanceof File) {
                            setFieldValue(`bannerUrl.${lang}`, file); // Update bannerUrl directly
                          } else {
                            showSnackbar(t('Error.lb_invalid_file'), 'error');
                          }
                        }}
                        title={t('Upload Banner')}
                        textButton={t('Browse')}
                        imageValidation={{
                          maxSize: 1 * 1024 * 1024, // 1MB file size limit
                          allowedFormats: ['image/jpg', 'image/jpeg', 'image/png'],
                          aspectRatio: 9 / 16,
                          aspectRatioSecondary: 9 / 19.5,
                          aspectRatioTolerance: 0.2
                        }}
                        onError={(errorMessage) => showSnackbar(errorMessage, 'error')}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container justifyContent="center">
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        const updatedBannerUrl = { ...values.bannerUrl };
                        delete updatedBannerUrl[lang];
                        setFieldValue('bannerUrl', updatedBannerUrl); // Remove the language key
                      }}
                    >
                      {t('Remove')}
                    </Button>
                  </Grid>
                </Grid>
              ))}
            </div>

            {/* Add New Language Banner */}
            <Grid container spacing={2} alignItems="center" sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <SelectInput
                  options={languages.filter((lang) => !(values.bannerUrl || {})[lang]).map((lang) => ({ value: lang, label: lang }))}
                  label={t('Add Language')}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setFieldValue(`bannerUrl.${newValue.value}`, null);
                    }
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>

            {/* Submit and Cancel Buttons */}
            {isSubmitting ? (
              <Grid container justifyContent="center">
                <CircularProgress sx={{ m: 'auto', mb: 3, mt: 3 }} />
              </Grid>
            ) : (
              <div style={{ display: 'flex', gap: '12px' }}>
                <Button
                  type="button"
                  onClick={() => setValue('0')}
                  sx={{
                    m: 'auto',
                    mb: 3,
                    mt: 3,
                    width: '100%',
                    fontWeight: '600',
                    color: color.fontColor,
                    backgroundColor: '#F2E8E8',
                    padding: '8px 32px',
                    '&:hover': { backgroundColor: '#E9E0E0' }
                  }}
                >
                  {t('System.lb_cancel')}
                </Button>
                <Button
                  type="submit"
                  sx={{ m: 'auto', mb: 3, mt: 3, width: '100%', fontWeight: '600', padding: '8px 32px' }}
                  variant="contained"
                  disabled={isSubmitting}
                >
                  {name}
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

CampaignsForm.propTypes = {
  setValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  action: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  menuItems: PropTypes.array.isRequired,
  languages: PropTypes.array.isRequired,
  defaultLanguage: PropTypes.string.isRequired
};

export default CampaignsForm;
