import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import ProtectedRoute from './ProtectedRoute';

//Pages
import Company from 'pages/Company';
import MenuItems from 'pages/MenuItems';
import GenerateQRCode from 'pages/GenerateQRCode';
import CookiePolicy from 'pages/Legal/legalPages/CookiePolicy';
import PrivacyPolicy from 'pages/Legal/legalPages/PrivacyPolicy';
import TermsAndConditions from 'pages/Legal/legalPages/TermsAndConditions';
import Campaigns from 'pages/Campaigns/index';

// render - dashboard
const Dashboard = Loadable(lazy(() => import('pages/dashboard')));

// render - sample page
// const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));

// render - utilities
// const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
// const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
// const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
// const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <ProtectedRoute>
      <MainLayout />
    </ProtectedRoute>
  ),
  children: [
    {
      path: 'dashboard',
      element: <Dashboard />
    },
    // {
    //   path: 'dashboard',
    //   children: [
    //     {
    //       path: 'default',
    //       element: <Dashboard />
    //     }
    //   ]
    // },
    {
      path: 'company',
      element: <Company />
    },
    {
      path: 'menu-items',
      element: <MenuItems />
    },
    {
      path: 'generate-qr-code',
      element: <GenerateQRCode />
    },
    {
      path: 'campaigns',
      element: <Campaigns />
    },
    //Terms and Policys
    {
      path: 'cookie-policy',
      element: <CookiePolicy />
    },
    {
      path: 'privacy-policy',
      element: <PrivacyPolicy />
    },
    {
      path: 'terms-and-conditions',
      element: <TermsAndConditions />
    }
    //TODO: Remove this routes below later
    // {
    //   path: 'color',
    //   element: <Color />
    // },
    // {
    //   path: 'sample-page',
    //   element: <SamplePage />
    // },
    // {
    //   path: 'shadow',
    //   element: <Shadow />
    // },
    // {
    //   path: 'typography',
    //   element: <Typography />
    // },
    // {
    //   path: 'icons/ant',
    //   element: <AntIcons />
    // }
  ]
};

export default MainRoutes;
