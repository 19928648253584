import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Button } from '@mui/material';
import { getImageForPath } from 'utils/imageUtils';
import PropTypes from 'prop-types';

//utils
import { findLanguageName } from 'utils/languageFinder';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

//Styles
import styles from './CampaignPreview.module.css';
import color from 'themes/colors';

import ASSETS from 'enums/assets';
import DateTypography from '../DateTypography/index';

const CampaignPreview = ({ selectedItem, defaultLanguage, languages, setValue }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

  const bannerImage =
    getImageForPath(
      selectedItem.bannerUrl?.[selectedLanguage] ||
        selectedItem.bannerUrl?.[defaultLanguage] ||
        Object.values(selectedItem.bannerUrl || {})[0],
      1125,
      1500,
      'webp'
    ) || ASSETS.IMAGES.PLACEHOLDERS.PLACEHOLDER_3_BY_4;

  return (
    <Grid className={styles.itemPreviewContainer} style={{ backgroundColor: color.backgroundColor }}>
      <div
        className={styles.banner}
        style={{
          backgroundImage: `url(${bannerImage})`
        }}
      >
        <div className={styles.title}>{capitalizeFirstLetter(selectedItem.name)}</div>
      </div>
      <div style={{ display: 'flex', gap: '10px' }}>
        {languages.map((i) => (
          <button key={i} onClick={() => setSelectedLanguage(i)} style={{ color: color.fontColor }} className={styles.languageButton}>
            <img src={ASSETS.ICONS.INFO.GLOBAL} alt="icon" />
            {findLanguageName(i)}
          </button>
        ))}
      </div>
      {/* Campaign Details */}
      <div style={{ marginTop: '24px' }}>
        <DateTypography label={t('Campaigns.lb_start_date', 'Start Date')} date={selectedItem.startDate} />
        <DateTypography label={t('Campaigns.lb_end_date', 'End Date')} date={selectedItem.endDate} />
      </div>

      <Button sx={{ width: '125px', ml: 'auto' }} variant="contained" onClick={() => setValue('3')}>
        {t('System.lb_edit')}
      </Button>
    </Grid>
  );
};

export default CampaignPreview;

CampaignPreview.propTypes = {
  selectedItem: PropTypes.object,
  defaultLanguage: PropTypes.string,
  languages: PropTypes.array,
  currencyCode: PropTypes.string,
  setValue: PropTypes.func
};
