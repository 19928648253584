// Other imports remain the same
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useContext } from 'react';
import { Box, Grid } from '@mui/material';

//Monitoring
import { monitoring } from '@Ethical-Algorithm/reactjs-ea-logger-kit';

import dayjs from 'dayjs';

//Redux / Context
import { useSelector } from 'react-redux';
import { SnackbarContext } from 'store/contexts/GlobalMessages/SnackbarContext';

import CampaignsList from './components/CampaignsList';
import {
  createCampaign,
  deleteCampaign,
  fetchCampaignItemsByCompanyID,
  isDateRangeOverlap,
  updateCampaign,
  uploadCampaignBanners
} from 'services/campaignRepository';
import { fetchCompanyById } from 'services/companyRepository';
import CampaignPreview from './components/CampaignPreview';
import CampaignsForm from './components/CampaignsForm';
import { fetchMenuItemsByCompanyID } from 'services/menuItemRepository';
import { isObjectFile } from 'utils/objectsTools';
import { deleteImagePath } from 'services/imagesRepository';

const Campaigns = () => {
  const [value, setValue] = useState('0'); // Container view state
  const [selectedItem, setSelectedItem] = useState({});
  const { t } = useTranslation();
  const showSnackbar = useContext(SnackbarContext);

  const ReduxData = useSelector((state) => state.mainUser);
  const companys = ReduxData.userDocument?.companyIDs ? Object.entries(ReduxData.userDocument?.companyIDs) : [];
  const selectedCompanyID = ReduxData.userSelectedCompanyID;

  const [campaignsItems, setCampaignsItems] = useState([]);
  const [defaultLanguage, setDefaultLanguage] = useState('');
  const [languages, setLanguages] = useState([]);
  const [currencyCode, setCurrencyCode] = useState('');
  const [menuItems, setMenuItems] = useState([]);

  // Fetch menu items by selected company ID
  useEffect(() => {
    const fetchMenuItems = async () => {
      if (selectedCompanyID) {
        try {
          const items = await fetchMenuItemsByCompanyID(selectedCompanyID);
          setMenuItems(items);
        } catch (error) {
          console.error('Error fetching menu items:', error);
        }
      }
    };
    fetchMenuItems();
  }, [selectedCompanyID]);

  // Fetch campaigns by selected company ID
  useEffect(() => {
    setValue('0');

    if (selectedCompanyID) {
      try {
        fetchCampaignItemsByCompanyID(selectedCompanyID).then((data) => {
          setCampaignsItems(data);
        });
        fetchCompanyById(selectedCompanyID).then((data) => {
          setLanguages(data.languages);
          setDefaultLanguage(data.defaultLanguage);
          setCurrencyCode(data.currencyCode);
        });
      } catch (error) {
        showSnackbar(t('Error.lb_error_fetching_campaign_items'));
        monitoring.error('Error fetching campaigns items', error);
      }
    } else {
      setDefaultLanguage('');
      setLanguages([]);
      setCampaignsItems([]);
      setCurrencyCode('');
    }
    //eslint-disable-next-line
  }, [selectedCompanyID]);

  //==========================| Menu Items CRUD |==========================//
  //==========================| Create Item |==========================//
  const submitCampaign = async (values) => {
    try {
      const { startDate, endDate, bannerUrl } = values;

      if (isDateRangeOverlap(campaignsItems, startDate, endDate)) {
        showSnackbar(t('Error.lb_campaign_date_overlap', 'Another campaign exists in this date range'), 'error');
        return;
      }

      const newCampaign = {
        companyID: selectedCompanyID,
        name: values.name.trim(),
        bannerUrl: {}, // Ensure bannerUrl is initialized
        menuItemID: values.menuItemID || null,
        startDate: dayjs(startDate).unix(),
        endDate: dayjs(endDate).unix()
      };

      const campaignID = await createCampaign(newCampaign);

      if (bannerUrl) {
        const uploadedBanners = await uploadCampaignBanners(selectedCompanyID, campaignID, bannerUrl);
        newCampaign.bannerUrl = uploadedBanners;
        await updateCampaign(campaignID, { bannerUrl: uploadedBanners });
      }

      setCampaignsItems([...campaignsItems, { ...newCampaign, id: campaignID }]);
      showSnackbar(t('Success.lb_item_created_successfully'), 'success');
      setValue('0');
    } catch (error) {
      console.error('Error creating campaign:', error);
      showSnackbar(t('Error.lb_error_creating_item'), 'error');
    }
  };

  //==========================| Delete Item |==========================//
  const deleteItem = async (values) => {
    try {
      await deleteCampaign(values.id);
      setCampaignsItems(campaignsItems.filter((i) => i.id !== values.id));

      // Delete image from storage
      if (values.bannerUrl) {
        for (const [, value] of Object.entries(values.bannerUrl)) {
          console.log('deleting', value);
          await deleteImagePath(value);
        }
      }
      showSnackbar(t('Success.lb_item_deleted_successfully'), 'success');
    } catch (error) {
      showSnackbar(t('Error.lb_error_deleting_item'), 'error');
      monitoring.error(`Error deleting item "${values.id}"`, error);
    }
  };

  //==========================| Update Campaign |==========================//
  const editCampaign = async (values) => {
    try {
      const { startDate, endDate, bannerUrl } = values;

      if (isDateRangeOverlap(campaignsItems, startDate, endDate, selectedItem.id)) {
        showSnackbar(t('Error.lb_campaign_date_overlap', 'Another campaign exists in this date range'), 'error');
        return;
      }

      // Delete image from storage
      languages.forEach(async (lang) => {
        const previousImage = selectedItem.bannerUrl[lang];
        const newImage = bannerUrl[lang];
        console.log('previousImage', previousImage);
        console.log('newImage', newImage);
        if (isObjectFile(newImage) && previousImage) {
          console.log('deleting', previousImage);
          await deleteImagePath(previousImage);
        } else if (previousImage && !newImage) {
          console.log('deleting', previousImage);
          await deleteImagePath(previousImage);
          selectedItem.bannerUrl[lang] = undefined;
        }
      });

      // Update new Banners
      const updatedBanners = bannerUrl
        ? await uploadCampaignBanners(selectedCompanyID, selectedItem.id, bannerUrl)
        : selectedItem.bannerUrl;

      console.log('previous bannerUrls', selectedItem.bannerUrl);
      console.log('new bannerUrls', updatedBanners);

      const updatedCampaign = {
        ...values,
        startDate: dayjs(startDate).unix(),
        endDate: dayjs(endDate).unix(),
        bannerUrl: {
          ...updatedBanners
        }
      };

      await updateCampaign(selectedItem.id, updatedCampaign);
      setCampaignsItems(
        campaignsItems.map((campaign) => (campaign.id === selectedItem.id ? { ...campaign, ...updatedCampaign } : campaign))
      );

      showSnackbar(t('Success.lb_item_updated_successfully'), 'success');
      setValue('0');
    } catch (error) {
      console.error('Error updating campaign:', error);
      showSnackbar(t('Error.lb_error_updating_item'), 'error');
    }
  };

  return (
    <Box>
      <Grid container spacing={2} sx={{ display: 'flex', width: '100%', fontFamily: 'Open Sans' }} columns={{ xs: 6, sm: 10, md: 12 }}>
        <Grid item xs={12} md={5}>
          <CampaignsList
            defaultLanguage={defaultLanguage}
            setValue={setValue}
            deleteItem={deleteItem}
            selectedCompanyID={selectedCompanyID}
            campaignsItems={campaignsItems}
            setSelectedItem={setSelectedItem}
            companys={companys}
          />
        </Grid>
        <Grid item xs={12} md={7}>
          {(() => {
            switch (value) {
              case '1':
                return (
                  <CampaignPreview
                    selectedItem={selectedItem}
                    defaultLanguage={defaultLanguage}
                    languages={languages}
                    setValue={setValue}
                    currencyCode={currencyCode}
                  />
                );
              case '2':
                return (
                  <CampaignsForm
                    key="create"
                    initialValues={{
                      bannerUrl: {},
                      name: '',
                      startDate: '',
                      endDate: '',
                      menuItemID: ''
                    }}
                    setValue={setValue}
                    name={t('Campaigns.lb_create_campaign')}
                    showSnackbar={showSnackbar}
                    action={submitCampaign}
                    languages={languages}
                    defaultLanguage={defaultLanguage}
                    currencyCode={currencyCode}
                    menuItems={menuItems}
                  />
                );
              case '3':
                return (
                  <CampaignsForm
                    key={selectedItem.id}
                    initialValues={{
                      bannerUrl: selectedItem.bannerUrl || {},
                      name: selectedItem.name || '',
                      startDate: dayjs.unix(selectedItem.startDate).format('YYYY-MM-DD'),
                      endDate: dayjs.unix(selectedItem.endDate).format('YYYY-MM-DD'),
                      menuItemID: selectedItem.menuItemID || ''
                    }}
                    setValue={setValue}
                    name={t('Campaigns.lb_update_campaign')}
                    showSnackbar={showSnackbar}
                    action={editCampaign}
                    languages={languages}
                    defaultLanguage={defaultLanguage}
                    currencyCode={currencyCode}
                    menuItems={menuItems}
                  />
                );
              default:
                return null;
            }
          })()}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Campaigns;
