import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import MainTitle from 'components/ea-design-system/MainTitle/index';
import TextInput from 'components/ea-design-system/TextInput';

// import MenuItemListButton from 'components/ea-design-system/MenuItemListButton';
import ItemListButton from 'components/ea-design-system/ItemListButton';
import { getImageForPath } from 'utils/imageUtils';

//Material-UI / Colors
import { Button, IconButton, Alert } from '@mui/material';
import color from 'themes/colors';
import { useTranslation } from 'react-i18next';

//Assets
import ASSETS from 'enums/assets';

import DeleteDialog from 'components/DeleteDialog';
import SelectCompany from 'components/SelectCompany';
import { debounce } from 'lodash';
import { formatUnixToDate } from 'utils/DataParsing';

const CampaignsList = ({ setValue, selectedCompanyID, campaignsItems, companys, defaultLanguage, setSelectedItem, deleteItem }) => {
  const { t } = useTranslation();

  const [searchString, setSearchString] = useState('');
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);

  //=======| Set filteredItems to menuItems by default |=======
  useEffect(() => {
    const fetchData = async () => {
      const campaignsData = await campaignsItems;
      setFilteredCampaigns(campaignsData);
    };

    fetchData();
  }, [campaignsItems]);

  //=======| Filter campaigns by search string |=======
  const debouncedFilter = debounce(() => {
    if (searchString) {
      const filtered = campaignsItems.filter((campaign) => {
        // Check if campaign name includes the search string
        const nameValues = typeof campaign.name === 'string' ? [campaign.name] : Object.values(campaign.name);
        return nameValues.some((value) => value.toLowerCase().includes(searchString.toLowerCase()));
      });
      setFilteredCampaigns(filtered);
    } else {
      setFilteredCampaigns(campaignsItems);
    }
  }, 500);

  useEffect(() => {
    debouncedFilter();
    return () => debouncedFilter.cancel();
  }, [searchString, campaignsItems, debouncedFilter]);

  const onSelectItem = (i) => {
    setValue('1');
    setSelectedItem(i);
  };

  //================| On Delete Item |================//
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [itemToRemove, setItemToRemove] = useState(null);

  const handleDeleteItem = async (item) => {
    await deleteItem(item);
    setOpenDeleteDialog(false);
  };

  return (
    <div
      style={{
        padding: '24px 16px',
        width: '100%',
        maxWidth: '100%',
        backgroundColor: color.backgroundColor,
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
        borderRadius: '16px'
      }}
    >
      <MainTitle
        style={{
          color: color.fontColor,
          borderBottom: `1px solid ${color.dividerColor}`,
          bgcolor: color.backgroundColor,
          paddingBottom: '8px',
          marginBottom: '16px'
        }}
      >
        {t('Campaigns.lb_campaign_items')}
      </MainTitle>

      <div>
        {(companys?.length === 0 || !companys) && (
          <Alert variant="filled" severity="info" sx={{ background: '#42a5f5', color: '#fff', fontWeight: '600' }}>
            {t('System.lb_not_registered_in_company')}
          </Alert>
        )}
        {filteredCampaigns.length > 0 && companys?.length > 1 && (
          <div style={{ fontSize: '18px', fontStyle: 'normal', fontWeight: 700 }}>Filter By:</div>
        )}

        <SelectCompany />
        {campaignsItems.length > 0 && (
          <TextInput
            label={t('System.lb_search_term')}
            placeholder={t('Campaigns.lb_search_placeholder')}
            onChange={(e) => setSearchString(e.target.value)}
            value={searchString}
            InputProps={{
              startAdornment: <img src={ASSETS.ICONS.ACTIONS.SEARCH} alt="search" />
            }}
          />
        )}
      </div>

      {/* ===============| List of Campaign Items |================== */}
      {filteredCampaigns.length > 0 ? (
        <div style={{ maxHeight: '500px', overflow: 'auto' }}>
          <div
            style={{
              padding: '16px 0px 8px 0px',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 700
            }}
          >
            {t('Campaigns.lb_campaign_result_items')}:
          </div>
          {filteredCampaigns.map((campaign) => {
            const bannerImage =
              getImageForPath(campaign.bannerUrl?.[defaultLanguage] || Object.values(campaign.bannerUrl || {})[0], 112, 112, 'webp') ||
              ASSETS.IMAGES.PLACEHOLDERS.PLACEHOLDER_3_BY_4;

            return (
              <div
                key={campaign.id}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '8px 0',
                  borderBottom: `1px solid ${color.dividerColor}`
                }}
              >
                <div>
                  <ItemListButton
                    title={typeof campaign.name === 'string' ? campaign.name : Object.values(campaign.name)[0]}
                    subTitle={`${t('Campaigns.lb_campaign_start_date')}: ${formatUnixToDate(campaign.startDate)} | ${t(
                      'Campaigns.lb_campaign_end_date'
                    )}: ${formatUnixToDate(campaign.endDate)}`}
                    image={bannerImage}
                    onSelectItem={() => onSelectItem(campaign)}
                  >
                    <IconButton
                      label="Update"
                      onClick={() => {
                        setValue('3');
                        setSelectedItem(campaign);
                      }}
                      aria-label="edit"
                      size="large"
                    >
                      <img src={ASSETS.ICONS.ACTIONS.EDIT} alt="edit" />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setOpenDeleteDialog(true);
                        setItemToRemove(campaign);
                      }}
                      aria-label="delete"
                      size="large"
                    >
                      <img src={ASSETS.ICONS.ACTIONS.DELETE} alt="delete" />
                    </IconButton>
                  </ItemListButton>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div style={{ textAlign: 'center', color: color.fontColor }}>{t('Campaigns.lb_no_campaigns', 'No campaigns found.')}</div>
      )}

      {/* ===============| Bottom Button and Others|================== */}
      <div style={{ textAlign: 'right' }}>
        {selectedCompanyID && (
          <Button sx={{ mt: '20px', fontWeight: '600' }} variant="contained" onClick={() => setValue('2')}>
            {t('Campaigns.lb_create_campaign')}
          </Button>
        )}
      </div>
      <DeleteDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        onDelete={() => handleDeleteItem(itemToRemove)}
      />
    </div>
  );
};

export default CampaignsList;

CampaignsList.propTypes = {
  setValue: PropTypes.func.isRequired,
  setSelectedItem: PropTypes.func.isRequired,
  selectedCompanyID: PropTypes.string,
  defaultLanguage: PropTypes.string,
  campaignsItems: PropTypes.array,
  companys: PropTypes.array,
  deleteItem: PropTypes.func.isRequired
};
