import dayjs from 'dayjs';
import { PLACEHOLDERS } from 'constants/globalConstants';

// Formats a date to "YYYY-MM-DD" (ISO format) for use in date inputs
export const formatDateToInput = (date) => {
  return dayjs(date).format('YYYY-MM-DD');
};

export const getTodayAsInputFormat = () => {
  return formatDateToInput(new Date());
};

// Converts a Unix timestamp to a formatted date string
export const formatUnixToDate = (unixTimestamp, format = 'YYYY-MM-DD') => {
  if (!unixTimestamp) return PLACEHOLDERS.NOTAPPLICABLE; // Fallback if date is not provided
  return dayjs.unix(unixTimestamp).format(format);
};
