import { collection, query, getDocs, where, doc, addDoc, deleteDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, updateMetadata } from 'firebase/storage';

// Utils
import { getTimestamp } from '../utils/timeManager';
import { db, storage } from 'zFirebaseConfigs/firebase';
import { getImageExtension } from 'utils/imageUtils';
import dayjs from 'dayjs';

export const fetchCampaignItemsByCompanyID = async (companyID) => {
  try {
    const campaignsItems = [];
    const querySnapshot = await getDocs(query(collection(db, 'campaigns'), where('companyID', '==', companyID)));
    querySnapshot.forEach((doc) => {
      campaignsItems.push({ id: doc.id, ...doc.data() });
    });
    return campaignsItems;
  } catch (error) {
    console.error(`Error fetching campaign items: ${error.message}`);
    throw error;
  }
};

// Create campaign
export const createCampaign = async (campaign) => {
  try {
    const docRef = await addDoc(collection(db, 'campaigns'), campaign);
    return docRef.id;
  } catch (error) {
    console.error(`Error creating campaign: ${error.message}`);
    throw error;
  }
};

// Delete campaign item by ID
export const deleteCampaign = async (campaignID) => {
  try {
    await deleteDoc(doc(db, 'campaigns', campaignID));
  } catch (error) {
    console.error(`Error deleting menu item: ${error.message}`);
    throw error;
  }
};

export const updateCampaign = async (id, updatedData) => {
  try {
    const docRef = doc(db, 'campaigns', id);
    await updateDoc(docRef, updatedData);
    return updatedData;
  } catch (error) {
    console.error(`Error updating campaign: ${error.message}`);
    throw error;
  }
};

/**
 * Delete All Campaigns by Company ID
 * @param {*} companyId - The company ID to delete all campaigns.
 */
export const deleteAllCampaigns = async (companyId) => {
  try {
    const querySnapshot = await getDocs(query(collection(db, 'campaigns'), where('companyID', '==', companyId)));
    querySnapshot.forEach((doc) => {
      deleteCampaign(doc.id);
    });
  } catch (error) {
    console.error(`Error deleting all campaigns: ${error.message}`);
    throw error;
  }
};

// Upload Campaign image to storage
export const uploadCampaignBanners = async (companyId, campaignID, banners) => {
  try {
    const uploadedBanners = {};

    for (const [lang, file] of Object.entries(banners)) {
      if (file instanceof File) {
        const extension = getImageExtension(file); // Pass the File object instead of file.name
        const filePath = `${companyId}/campaigns/${campaignID}_${lang}_${getTimestamp()}.${extension}`;
        const storageRef = ref(storage, filePath);

        const uploadTask = uploadBytesResumable(storageRef, file);
        await uploadTask;

        // Update metadata to set cache control
        const metadata = {
          cacheControl: 'public, max-age=31556926'
        };
        await updateMetadata(storageRef, metadata);

        uploadedBanners[lang] = filePath;
      } else {
        // If the banner is already uploaded, keep its path
        uploadedBanners[lang] = file;
      }
    }

    return uploadedBanners;
  } catch (error) {
    console.error(`Error uploading campaign banners: ${error.message}`);
    throw error;
  }
};

// TODO: move to rules layer
// Checks if a date range overlaps with any existing campaigns.
export const isDateRangeOverlap = (campaigns, startDate, endDate, editingId = null) => {
  const newStartDate = dayjs(startDate).unix();
  const newEndDate = dayjs(endDate).unix();

  return campaigns.some((campaign) => {
    // Exclude the campaign being edited
    if (editingId && campaign.id === editingId) return false;

    const existingStartDate = dayjs.unix(campaign.startDate).unix();
    const existingEndDate = dayjs.unix(campaign.endDate).unix();

    // Check for overlap
    return newStartDate <= existingEndDate && newEndDate >= existingStartDate;
  });
};
